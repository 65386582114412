//@flow

import React, {useEffect} from "react";

import {connect} from "react-redux";
import NCard from "../../../../../../components/simple/Containers/NCard/NCard";
import AlertPaynetSync from "../../../../../../components/simple/Containers/AlertCard/AlertPaynetSync";
import type {contract, organizationId} from "../../../../../../services/flowTypes/dataFlowTypes";
import itemDataParamsEditActions
  from "../../../../../../store/organization/item/itemDataParamsEdit/itemDataParamsEditActions";
import type {onSetItemDataParamsEdit,} from "../../../../../../services/flowTypes/propFnFlowTypes";
import PaynetParams from "../ContractMainDataEdit/contractMainDataEdit/PaynetParams";
import ContractMainDataFormGroup from "../ContractMainDataEdit/contractMainDataEdit/ContractMainDataFormGroup";
import text from "../../../../../../services/localization/text";
import {contractEditPageIds} from "../../../../../../tests/testIds";

type Props = {
  organizationId: organizationId,
  contractMainData: contract,
  onSetItemDataParamsEdit: onSetItemDataParamsEdit
}

const ContractSourcesView = (props: Props) => {

  const {organizationId, contractMainData, onSetItemDataParamsEdit} = props;

  useEffect(() => {
    if (contractMainData.params && contractMainData.params.length > 0 && onSetItemDataParamsEdit) {
      onSetItemDataParamsEdit(contractMainData.params)
    }
  }, [contractMainData, onSetItemDataParamsEdit])
  return <>
    {
      contractMainData.isNotCompliedWithPaynetParams ? (
        <NCard addClasses="mb_2du">
          <AlertPaynetSync
            agentIdIsNotCompliedWIthPaynetParams={contractMainData.agentIdIsNotCompliedWIthPaynetParams}
            organizationId={organizationId}/>
        </NCard>
      ) : null
    }
    <NCard addClasses="mb_2du">
      <ContractMainDataFormGroup propName={"ignoreGates"}
                                 label={text.agent.ignoreGates}
                                 formFieldType={'checkbox-boolean'}
                                 idForTest={contractEditPageIds.ignoreGates}
      />
    </NCard>
    <PaynetParams/>
  </>

}
const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId,
    contractMainData: state.organization.item.contractMainData,
    configFinTerms: state.organization.organizationData.configFinTerms.configSourceParams,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetItemDataParamsEdit: (data) => dispatch({type: itemDataParamsEditActions.ITEM_DATA_PARAMS_SET, value: data}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractSourcesView);
