import {byTxFinTermParamTooltips, byVolumeParamTooltips} from "./commonStrings";

/* eslint-disable no-template-curly-in-string */


const textRu = {
  logoStart: 'Flow',
  logoEnd: 'Doc',
  admin: 'Администратор',
  lawyer: 'Юрист',
  accountant: 'Бухгалтер',
  logIn: 'Войти',
  logOut: 'Выход',

  organization: 'Организация',
  sbc: 'ЭсБиСи Технологии',

  contracts: 'Контракты',
  agents: 'Контрагенты',
  reports: 'Отчеты',

  newContract: 'Новый контракт',
  newAgent: 'Новый контрагент',
  newGroupFinTerm: 'Новое условие',
  newGroupFinTerms: 'Новые условия',
  upload: 'Загрузить',
  chooseFile: 'Выбрать файл',
  uploadDocument: 'Загрузить документ',
  substituteDocument: 'Заменить',
  newActTemplate: 'Создать шаблон',
  contractLabel: 'Контракт',
  agentLabel: 'Контрагент',
  reportLabel: 'Отчет',

  advancedSearchHeader: 'Расширенный поиск',
  btnDel: 'Удалить',
  btnEdit: 'Редактировать',
  btnAdd: 'Добавить',
  checkedFields: 'Выбрано полей для добавления:',
  nullify: 'Сбросить',

  status: 'Статус',
  validityTerm: 'Дата заключения',
  all: 'Все',
  start: 'Начало',
  end: 'Конец',
  startDate: 'Начало',
  endDate: 'Конец',
  date: 'Дата',
  back: 'Назад',
  forward: 'Вперед',
  reportStatusLabel: 'Статус выполнения',
  reportAccountStatusLabel: 'Статус отчета',
  linkDate: 'Дата связи',
  endDateNotSet: 'Не указано',
  linkDateNotSet: 'Не указано',
  linkTypeNotSet: 'Не указано',

  type: 'Тип',
  number: 'Номер',
  createDate: 'Дата создания',
  name: 'Наименование',
  signingDate: 'Дата подписания',
  comment: 'Комментарий',
  projectName: 'Код номенклатурной группы (Проект/Направление)',
  ignoreInExport: 'Не учитывать при формировании файла экспорта данных',
  terminationDate: 'Дата расторжения',
  terminationReason: 'Причина расторжения',
  agentCode: 'Код контрагента',
  tags: 'Теги',
  tagsPlaceholder: 'Введите тег или выберите из списка',
  merchantIdPlaceholder: 'Введите ID мерчантов',
  managerIdPlaceholder: 'Введите ID менеджеров',
  processorIdPlaceholder: 'Введите ID процессоров',
  clearAll: 'Очистить',
  addParamsTitle: 'Дополнительно',
  addBlock: '+ Добавить блок',
  addField: 'Добавить поле',
  addFieldsModalTitle: 'Добавление поля',
  noFieldsToAdd: 'Нет доступных полей',
  header1c: 'Параметры для 1С',
  contractCode1c: 'Код договора',
  inn1c: 'ИНН контрагента',
  name1c: 'Наименование контрагента',
  nomenNumber1c: 'Код номенклатуры акта',
  selectContractType: 'Выберите тип контракта',
  noAvailableContractTypes: 'Не определены типы контрактов',
  reportTotalExpression: 'Сумма итого',
  reportTotalCountExpression: 'Количество проведенных операций ',
  reportTotalAmountExpression: 'Сумма проведенных операций',

  dataSources: 'Источники данных',
  noData: 'Нет данных',

  save: 'Сохранить',
  cancel: 'Отмена',

  ONCE: 'Разово',
  MONTHLY: 'Ежемесячно',
  QUARTERLY: 'Ежеквартально',
  ANNUAL: 'Ежегодно',
  BY_VOLUME_SIMPLE: 'Простая ставка от объема',
  BY_VOLUME_COMPLEX: 'Ступенчатая ставка от объема',
  BY_TX_VOLUME: 'Потранзакционный расчет',
  BYRATE: 'Фиксированная процентная ставка',
  BY_HOURS: 'Почасовая ставка',
  BY_TX_COUNT: 'Простая ставка по количеству транзакций',
  BY_TX_COUNT_SIMPLE: 'Простая ставка от объема количества транзакций',
  BY_TX_COUNT_COMPLEX: 'Ступенчатая ставка от объема количества транзакций',
  FIXED: 'Фиксированная сумма за период',
  FIXED_NO_PERIOD: 'Фиксированная сумма',
  MANUAL: 'Ручной ввод',
  MIN: 'Минимальная фиксированная сумма за период',
  PNE: 'PPG (RU)',
  PNE_EU: 'PPG (EU)',
  REDMINE: 'Redmine',
  EMPTY: 'Без источника',
  sourceNames: {
    MANUAL: 'Ручной ввод',
    PNE: 'PPG (RU)',
    PNE_EU: 'PPG (EU)',
    REDMINE: 'Redmine',
    EMPTY: 'Без источника'
  },

  mainData: 'Основные данные',
  addAgreements: 'Доп.соглашения',
  addAgreementsTitle: 'Дополнительные соглашения',
  finTerms: 'Финансовые условия',
  documents: 'Документы',
  actTemplates: 'Шаблоны актов',
  history: 'История изменений',
  historyTitle: 'История',
  relatedAgents: 'Связанные контрагенты',
  relatedContracts: 'Связанные контракты',
  userHistory: 'История действий',

  finTerm: 'Финансовое условие',
  deleteFinTermModalTitle: 'Удаление',
  preventDeleteFinTermModalTitle: 'Удаление',
  preventDeleteFinTermModal: {
    warningContainerTitle: 'Удаление невозможно!',
    warningContainerText: 'Финансовое условие привязано к действующим шаблонам актов',
    modalText: 'Для удаления данного финансового условия, свяжитесь с администратором'
  },
  payer: 'Плательщик',
  payee: 'Получатель',
  payerMerchantId: 'Торговец плательщика',
  payeeMerchantId: 'Торговец получателя',
  currency: 'Валюта',
  payoutPeriod: 'Период выплат',
  source: 'Источник данных',
  addFinTerm: '+ Добавить условие',
  finTermTitle: 'Условие по дате',
  newFinTermTitle: 'Новое условие по дате',
  finTermDocument: 'Основное/дополнительное соглашение',
  finTermMinRate: 'Минимальная ставка от общей суммы',
  noDocuments: 'Документы не загружены',
  selectDocument: 'Выбрать документ',
  ratePercent: 'Процент',
  rateMin: 'Минимум',
  rateAbs: 'Абсолют',
  rateAbsForTxTypes: 'Стоимость',
  rateAbsForMinType: 'Минимум',
  minAmount: 'Сумма от',
  maxAmount: 'Сумма до',
  minCount: 'Количество от',
  maxCount: 'Количество до',
  rateHours: 'Почасовая ставка',
  terminalId: 'Терминал IDs',
  merchantId: 'Мерчант IDs',
  gatewayId: 'Шлюз IDs',
  transactionStatus: 'Статус транзакции',
  transactionType: 'Тип транзакции',
  processorId: 'Процессор IDs',
  cardType: 'Тип карты',
  managerId: 'Менеджер IDs',

  projectId: 'Проект ID',
  trackers: 'Имя трекеров',
  userIds: 'Пользователь IDs',
  usersExcluded: 'исключить указанные IDs пользователя',
  usersExcludedValue: {
    true: 'Да',
    false: 'Нет'
  },
  ignoreInExportValue: (value) => value ? 'Да' : 'Нет',
  sourceParams: {
    confirmDelete: 'Удалить:',
    confirmDeleteDescription: 'Все данные будут потеряны',
    pasteDataFromContractSource: 'Вставить значения из контракта',
    pastedDataFromContractSource: 'Значения получены из контракта',
    viewLabel: {
      PNE: {
        terminalId: 'Терминал IDs',
        merchantId: 'Мерчант IDs',
        merchantIds: 'Мерчант IDs',
        gatewayId: 'Шлюз IDs',
        processorId: 'Процессор IDs',
        processorIds: 'Процессор IDs',
        transactionStatus: 'Статус транзакции',
        transactionType: 'Тип транзакции',
        cardType: 'Тип карты',
        managerId: 'Менеджер IDs',
        managerIds: 'Менеджер IDs',
        projectId: 'Проект IDs',
        projectIds: 'Проект IDs',
        direction: 'Тип направления',
        procIdsExcluded: 'исключить указанные IDs процессора',
        mngrMercIdsExcluded: 'исключить указанные IDs мерчантов менеджера',
        mngrIdsExcluded: 'исключить указанные IDs менеджера',
        mercIdsExcluded: 'исключить указанные IDs мерчанта',
      },
      PNE_EU: {
        terminalId: 'Терминал IDs',
        merchantId: 'Мерчант IDs',
        merchantIds: 'Мерчант IDs',
        gatewayId: 'Шлюз IDs',
        processorId: 'Процессор IDs',
        processorIds: 'Процессор IDs',
        transactionStatus: 'Статус транзакции',
        transactionType: 'Тип транзакции',
        cardType: 'Тип карты',
        managerId: 'Менеджер IDs',
        managerIds: 'Менеджер IDs',
        projectId: 'Проект IDs',
        projectIds: 'Проект IDs',
        direction: 'Тип направления',
        procIdsExcluded: 'исключить указанные IDs процессора',
        mngrMercIdsExcluded: 'исключить указанные IDs мерчантов менеджера',
        mngrIdsExcluded: 'исключить указанные IDs менеджера',
        mercIdsExcluded: 'исключить указанные IDs мерчанта',
      },
      REDMINE: {
        projectId: 'Проект ID',
        trackers: 'Имя трекеров (через запятую)',
        userIds: 'Пользователь IDs (через запятую)',
        usersExcluded: 'исключить указанные IDs пользователя'
      }
    },
    editLabel: {
      PNE: {
        terminalId: 'Терминал IDs (через запятую)',
        merchantId: 'Мерчант IDs (через запятую)',
        merchantIds: 'Мерчант IDs (через запятую)',
        gatewayId: 'Шлюз IDs (через запятую)',
        processorId: 'Процессор IDs (через запятую)',
        processorIds: 'Процессор IDs (через запятую)',
        transactionStatus: 'Статус транзакции',
        transactionType: 'Тип транзакции',
        cardType: 'Тип карты',
        managerId: 'Менеджер IDs (через запятую)',
        managerIds: 'Менеджер IDs (через запятую)',
        projectId: 'Проект IDs (через запятую)',
        projectIds: 'Проект IDs (через запятую)',
        direction: 'Тип направления',
        procIdsExcluded: 'исключить указанные IDs процессора',
        mngrMercIdsExcluded: 'исключить указанные IDs мерчантов менеджера',
        mngrIdsExcluded: 'исключить указанные IDs менеджера',
        mercIdsExcluded: 'исключить указанные IDs мерчанта',
      },
      PNE_EU: {
        terminalId: 'Терминал IDs (через запятую)',
        merchantId: 'Мерчант IDs (через запятую)',
        merchantIds: 'Мерчант IDs (через запятую)',
        gatewayId: 'Шлюз IDs (через запятую)',
        processorId: 'Процессор IDs (через запятую)',
        processorIds: 'Процессор IDs (через запятую)',
        transactionStatus: 'Статус транзакции',
        transactionType: 'Тип транзакции',
        cardType: 'Тип карты',
        managerId: 'Менеджер IDs (через запятую)',
        managerIds: 'Менеджер IDs (через запятую)',
        projectId: 'Проект IDs (через запятую)',
        projectIds: 'Проект IDs (через запятую)',
        direction: 'Тип направления',
        procIdsExcluded: 'исключить указанные IDs процессора',
        mngrMercIdsExcluded: 'исключить указанные IDs мерчантов менеджера',
        mngrIdsExcluded: 'исключить указанные IDs менеджера',
        mercIdsExcluded: 'исключить указанные IDs мерчанта',
      },
      REDMINE: {
        projectId: 'Проект ID',
        trackers: 'Имя трекеров (через запятую)',
        userIds: 'Пользователь IDs (через запятую)',
        usersExcluded: 'исключить указанные IDs пользователя'
      }
    }
  },
  hours: 'Количество часов',
  select: {
    placeholder: {
      transactionType: 'Выберите тип транзакции',
      transactionStatus: 'Выберите статус транзакции',
      cardType: 'Выберите тип карты',
      payer: 'Выберите плательщика',
      payee: 'Выберите получателя',
      payerMerchant: 'Выберите торговца плательщика',
      payeeMerchant: 'Выберите торговца получателя',
      source: 'Выберите источник данных',
      type: 'Выберите тип условия',
      currency: 'Выберите валюту',
      customFunction: 'Выберите кастомную функцию',
      direction: 'Выберите тип направления',
      merchantIds: 'Введите ID мерчантов',
      managerIds: 'Введите ID менеджеров',
      processorIds: 'Введите ID процессоров',
    }
  },
  terms: 'Условия',
  finTermDocumentTooltip: 'Выберите из списка загруженных документов контракта документ, на котором основывается финансовое условие',
  hour: 'час',
  relatedAgentsModalTitle: 'Привязать контрагентов к контракту',
  contractLinkedContractsModalTitle: 'Привязать контракты к контракту',

  documentsModalTitle: 'Загрузка документа',
  documentIsUploaded: 'Загружен',
  prepositionAtTime: 'в',
  description: 'Описание',
  deleteDocumentModalTitle: 'Удаление',
  volumeLimit: 'Возможна загрузка файлов не более 20 Мб.',
  allowedDocTypes: 'Поддерживаемые форматы файлов:',
  allowedDocTypesList: 'TXT, PDF , XLSX, DOCX.',

  generateReport: 'Сгенерировать акт',
  wasCreated: 'Создано',
  wasChanged: 'Изменено',
  baseDoc: 'Шаблон',
  addendumTemplateFileLabel: 'Шаблон приложения',
  deleteActTemplateModal: 'Удаление',
  generateReportModalTitle: 'Генерация отчета',
  generateReportModalSubTitle: 'Выберите период для создания отчета',
  btnCreate: 'Создать',
  btnProceed: 'Продолжить',
  month: 'Месяц',
  year: 'Год',
  actTemplate: 'Шаблон акта',
  newActTemplateTitle: 'Новый шаблон акта',
  fileWithTags1: 'Система ',
  fileWithTags2: 'автоматически заменит',
  fileWithTags3: ' их на соответствующие реквизиты контракта и связанных с ним контрагентов.',
  fileWithTagsLink: 'Файл с тегами.xlsx',
  finTermsExplanation: 'Выберите или добавьте финансовые условия для генерации списка тегов, необходимых для расчета итоговой суммы за выбранный период. И вставьте их в шаблон.',
  selectConditions: 'Выбрать условия',
  allConditionsAreSelected: 'Нет условий',
  details: 'Детали',
  uploadActTemplateFile1: 'Загрузите ваш ',
  uploadActTemplateFile2: 'шаблон акта',
  uploadActTemplateFile2addendum: 'шаблон приложения',
  uploadActTemplateFile3: ', в котором использованы спец.символы - ',
  uploadActTemplateFile4: 'теги',
  uploadAddendumTemplateFileText: 'Для загрузки шаблона необходимо перейти в режим редактирования',
  replaceFile: 'Заменить файл',
  chooseActTemplateDoc: 'Выбрать шаблон акта',
  chooseAddendumTemplateFile: 'Выбрать шаблон приложения',

  sorting: {
    createDate: 'Дата создания',
    startDate: 'Дата заключения',
    contractNo: 'Номер контракта',
    id: {
      contracts: 'ID контракта',
      agents: 'ID контрагента'
    },
    name: 'Название'
  },


  contract: {
    status: {
      ACTIVE: 'Действует',
      TERMINATED: 'Расторгнут',
    },
    linkType: 'Тип связи',
    editLinkedItem: 'Редактировать связанный контракт',
    isCheckedAndVerified: 'Изменения в финансовых условиях проверены'
  },
  addAgreement: {
    status: {
      ACTIVE: 'Действует',
      TERMINATED: 'Не действует',
    }
  },
  agent: {
    status: {
      ACTIVE: 'Активный',
      INACTIVE: 'Неактивный',
    },
    editLinkedItem: 'Редактировать связанного контрагента',
    ignoreGates: "Игнорировать параметры шлюзов"
  },
  addService: {
    status: {
      DRAFT: 'Черновик',
      UPLOADED: 'Выгружен',
      ACTIVE: 'Действует',
      INACTIVE: 'Неактивна'
    }
  },

  search: {
    placeholder: {
      contracts: 'Поиск контракта по названию и тегам',
      agents: 'Поиск контрагента по названию и тегам',
      general: 'Поиск'
    },
    totalCount: 'Найдено'
  },

  emptyListTitle:'Здесь ничего нет',
  emptyListTitleSearchResultsMode: 'Ничего не найдено',
  emptyListMsgEnd:', нажав на кнопку',
  emptyListLabel: {
    finTermsView: 'Добавьте новое финусловие',
    contractAgents: 'Добавьте контрагента к контракту',
    itemDocuments: 'Выберите файлы или документы, которые необходимо загрузить',
    actTemplatesView: 'Добавьте новый шаблон',
    userOrganizations: 'Добавьте организацию',
    itemParams: 'Добавьте новый параметр',
    addAgreements: 'Добавьте доп.соглашение',
    addServices: 'Добавьте доп.услугу',
    agentGatesParams: 'Нажмите на кнопку, перед этим проверив указан ли Мерчант IDs в ',
    agentGatesParamsLink: 'Основных данных',
    searchResultsMode: 'Попробуйте изменить параметры поиска'
  },
  noRelatedContracts: 'Связанные контракты не обнаружены',

  error: {
    required: 'Поле не заполнено',
    dateFormat: 'Формат даты - ДД.ММ.ГГГГ',
    email: 'Email имеет неправильный формат',
    userMainDataOrganizationLength: 'Организации не выбраны',
    maxLength31: 'Поле вмещает не более 31 знака',
    maxLength128: 'Поле вмещает не более 128 знаков',
    maxLength150: 'Поле вмещает не более 150 знаков',
    maxLength256: 'Поле вмещает не более 256 знаков',
    maxLength1000: 'Поле вмещает не более 1000 знаков',
    maxLength2048: 'Поле вмещает не более 2048 знаков',
    oneInRatesArrRequired: 'Одно из полей комиссии должно быть заполнено',
    oneInParamsArrRequired: 'Одно из полей параметров источника должно быть заполнено',
    sequenceDates: 'Несоответствие дат',
    complianceWithContractDates: 'Несоответствие датам контракта',
    oneFinTermRequired: 'Не выбраны финансовые условия',
    notEmptyFinTermsArr: 'Не добавлены условия по дате',
    notEmptyContractMainDataFilesArr: 'Не добавлены основные документы',
    notEmptyFinTermParamsArr: 'Не добавлены комиссии',
    noActTemplateFile: 'Не выбран файл',
    minMaxCompliance: 'Несоответствие значений в диапазонах',
    groupFinTermNameMatchIsProhibited_withArgs: 'Фин.условие с таким названием уже существует',
    convertedTagIsNotAdded: 'Не добавлен тег с конвертацией валюты',
    conversionCurrencyIsNotMatched: 'Валюты конвертации не совпадает с валютой расчета',
    conversionSourceIsNotMatched: 'Источники конвертации не совпадают',
    conversionDateIsNotMatched: 'Дата конвертации должна быть определена как дата транзакции',
    payoutPeriodCompliance: 'Период выплат в фин.условии не соответствует периоду выплат в шаблоне',
    notEmptySheetsArr: 'Не добавлен список услуг с валютой',
    notEmptyCalculatedItemsArr: 'Не добавлен список услуг',
    notEmptyTerminalsArr: 'Не выбраны терминалы',
    forbiddenFileNameSymbols: 'Запрещенные символы в названии файла'
  },
  errorModalTitle: 'Произошла ошибка',
  errorMsgTitle: 'Ошибка',
  warningModalTitle: 'Предупреждение',


  initiator: 'Инициатор',
  selectUser: 'Пользователь',
  allUsers: 'Все пользователи',
  historyActionTypes: {
    UPLOAD_FILE: 'Загружен документ',
    DELETE_FILE: 'Удален документ',
    UNDELETE_FILE: 'Восстановлен документ',
    UPDATE_FILE: 'Обновлен документ',
    CREATE: 'заведен',
    UPDATE: 'изменен',
    CREATE_FINTERMS: 'Добавлены финансовые условия',
    UPDATE_FINTERMS: 'Изменены финансовые условия',
    DELETE_FINTERMS: 'Удалены финансовые условия',
    UPLOAD_ACT_TEMPLATE: 'Загружен шаблон акта',
    UPDATE_ACT_TEMPLATE: 'Обновлён шаблон акта',
    DELETE_ACT_TEMPLATE: 'Удален шаблон акта',
    UPDATE_REPORT_STATUS: 'Изменен статус отчета',
    AUTO_ADJUST_REPORT: 'Автоматически скорректирован отчет',
    USER_LOGIN: 'Пользователь вошел в приложение',
    USER_SET_PASSWORD: 'Пользователь установил пароль',
    USER_SET_TWO_FACTOR_AUTH_KEY: 'Пользователь установил код аутентификации',
    CREATE_ADD_AGREEMENT: 'Заведено доп.соглашение',
    UPDATE_ADD_AGREEMENT: 'Изменено доп.соглашение',
    DELETE_ADD_AGREEMENT: 'Удалено доп.соглашение',
    CREATE_ADD_SERVICE: 'Заведена доп.услуга',
    UPDATE_ADD_SERVICE: 'Изменена доп.услуга',
    DELETE_ADD_SERVICE: 'Удалена доп.услуга'
  },
  historyObjectTypes: {
    CONTRACT: 'Договор',
    AGENT: 'Контрагент',
    REPORT: 'Отчет',
    USER: 'Пользователь',
    ORGANIZATION: 'Организация'
  },
  historyObjectAction: {
    CONTRACT: {
      CREATE: 'Договор заведен',
      UPDATE: 'Договор изменен'
    },
    AGENT: {
      CREATE: 'Контрагент заведен',
      UPDATE: 'Контрагент изменен'
    },
    REPORT: {
      CREATE: 'Отчет создан',
      UPDATE: 'Отчет изменен'
    },
    USER: {
      CREATE: 'Пользователь заведен',
      UPDATE: 'Пользователь изменен'
    },
    ORGANIZATION: {
      CREATE: 'Организация создана',
      UPDATE: 'Организация изменена'
    }
  },

  reportsTitle: 'Отчеты',
  reportsSearchPlaceholder: 'Номер акта',
  reportPeriodDate: 'Период отчета',
  reportCreateDate: 'Дата создания',
  noReports: 'Здесь будет отображен перечень отчетов',
  reportPeriod: 'Период отчета',
  reportNumber: 'Номер акта:',
  exportFileLabel: 'Файл экспорта данных',
  addServiceReportFileLabel: 'Доп. услуги',
  downloadReport: 'Скачать отчет',
  currentStatus: 'Текущий статус',
  updateStatus: 'Сменить статус',
  updateStatusTitle: 'Смена статуса',
  newStatus: 'Новый статус',
  actDate: 'Дата акта',
  lastStatusUpdate: 'Последнее изменение статуса',
  noStatusesToUpdate: 'Изменение статуса отчета невозможно',

  rateAmount: 'Сумма комиссий',
  totalAmount: 'Сумма оборота',
  count: 'Количество транзакций',
  reportTotal: 'Сумма итого',

  reportFinTermParamLabel: {
    rateAmount: 'Сумма комиссий',
    totalAmount: 'Сумма оборота',
    count: 'Количество транзакций',
    rate: "Ставка",
    hours: "Часы",
    amount: "Общая сумма"
  },


  reportsSearch2: 'Начало',
  reportsSearch3: 'Конец',
  reportsSearch4: 'Статус',
  reportsSearch5: 'Дата',

  today: 'Сегодня',
  yesterday: 'Вчера',
  currentMonth: 'Текущий месяц',
  prevMonth: 'Прошлый месяц',
  currentQuarter: 'Текущий квартал',
  prevQuarter: 'Прошлый квартал',



  reportsSearchResultsItem2: 'Основание',


  reportStatus: {
    ALL: "Все",
    SUCCESS: "Успешно",
    CREATED: "Ожидание",
    SCHEDULED_FOR_ADJUST : "Ожидание корректировки",
    PROCESSING: "В процессе",
    FAILED: "Ошибка"
  },
  reportStatusInProcess: 'Подготавливается',
  reportAccountStatus: {
    ALL: "Все",
    DRAFT: "Черновик",
    PREPARED: "Подготовлен",
    UPLOADED: "Выгружен",
    CANCELLED: "Отменен",
    VERIFIED: "Согласован",
    PAID_PARTIALLY: "Оплачен частично",
    PAID: "Оплачен",
    ADJUSTED: "Скорректирован"
  },
  blockName: 'Наименование блока',

  tileIconTooltip: {
    finterms: 'Финусловия',
    documents: 'Документы',
    templates: 'Шаблоны',
    contracts: 'Контракты',
    agents: 'Контрагенты',
    organizations: 'Организации'
  },

  declension: {
    condition: {
      single: 'условие',
      few: 'условия',
      many: 'условий'
    },
    organization: {
      single: 'организация',
      few: 'организации',
      many: 'организаций'
    },
    contract: {
      single: 'контракт',
      few: 'контракта',
      many: 'контрактов'
    },
    agent: {
      single: 'агент',
      few: 'агента',
      many: 'агентов'
    },
    addService: {
      single: 'услуга',
      few: 'услуги',
      many: 'услуг'
    },

  },

  errorCode: {
    200: "Невозможно удалить группу финусловий",
    300: "Ошибка при генерации отчета",
    400: "Неправильный запрос",
    401: "Доступ запрещен",
    404: 'По запрашиваемому адресу ничего не найдено',
    413: `Превышен максимальный размер загружаемого файла`,
    419: "Дублирование данных: email",
    420: "Дублирование данных",
    421: "Неверный тип параметра функции",
    422: `Значение параметра не соответствует установленным требованиям`,
    500: `Ошибка на сервере`,
    505: 'Не нашли организацию',
    3001: (param) => `Неверный тип объекта: ${param}`,
    3002: (param) => `Язык ${param} не поддерживается`,
    3003: (param) => `Валюта ${param} не поддерживается`,
    3004: (param) => `Сумма ${param} не может быть пустой или отрицательной`,
    3005: (param) => `Выражение ${param} некорректно`,
    3006: `Нет закрывающей скобки в конце файла`,
    3007: `Выражение не может быть пустым`,
    3008: (param) => `Неверное имя функции ${param}`,
    3009: (param) => `Неверное количество параметров функции: ${param}`,
    3010: (param) => `Неверный тип параметра функции: ${param}`,
    3011: (param) => `Неверный формат числа: ${param}`,
    3012: (param) => `Неверный формат числа: ${param}`,
    3013: `Ошибка при обработке отчета`,
    3014: (param) => `Невозможно определить тип шаблона: ${param}`,
    3015: `Ни один из параметров (Терминал ID, Шлюз ID, Процессор ID) не указан`,
    3016: (param) => `Невозможно преобразовать отчет в pdf формат: ${param}`,
    3017: "Отсутсвует название файла отчета",
    3018: "Устаревший формат doc не поддерживается, используйте docx формат",
    3019: "Устаревший формат xls не поддерживается, используйте xlsx формат",
    3020: `Неверный тип файла шаблона`,
    3021: (param) => `Формат файла отчета не поддерживается: ${param}`,
    3022: (param) => `Вы пытаетесь совершить операцию с разными валютами: ${param}`,
    4001: (param) => `За указанный период уже существует подготовленный отчет #${param}`,
    5001: "Нарушение целостности базы данных",
    5002: (param) => `Невозможно удалить тип контракта/тип связи, т.к. существуют сущности с данным типом: ${param}`,
    5003: (param) => `Невозможно удалить источник финусловий, т.к. существуют связанные с ним финусловия: ${param}`,
    5004: 'Период действия контракта не совпадает с периодом действия финусловия',
    5005: 'Период действия контракта не совпадает с периодом действия доп.соглашения',
    5006: 'Период действия доп.соглашения не совпадает по крайней мере с одним периодом действия финусловия',
    5007: (param) => `Данные контрагентов (IDs: ${param}) не соответствуют данным PNE`,
    5010: (param) => `Дата окончания фин условия ${param[0]} = ${param[1]}, начальная дата ${param[2]} = ${param[3]}, ожидаем разницу больше, чем один день`,
    5011: (param) => `Тип фин условия не соответсвует типу конвертера: ожидаем '${param[0]}', но получаем '${param[1]}'`,
    5050: 'Требуется хотя бы одно активное фин условие',
  },
  goToPreparedReport: 'Перейти к подготовленному отчету',
  goToMainPage: 'Вернуться на главную страницу',

  reportsSubMenu: {
    ALL: "Все",
    DRAFT: "Черновики",
    PREPARED: "Подготовлен",
    ADJUSTED: "Скорректирован",
    UPLOADED: "Выгружен",
    CANCELLED: "Отменен",
    VERIFIED: "Согласован",
    PAID_PARTIALLY: "Оплачен частично",
    PAID: "Оплачен"
  },
  areChosen: "Выбрано:",
  download1CExportFile: "Выгрузить 1С файл",
  adjustment: "Корректировка",
  autoAdjustment: "Автоматическая корректировка",
  manualAdjustment: "Ручная корректировка",
  uploadDoc: "Загрузить документ",
  adjustmentHistory: "История корректировок",
  adjustmentType: {
    AUTO_ADJUST: 'Скорректирован автоматически',
    MANUAL_ADJUST: 'Скорректирован вручную'
  },

  organizations: 'Организации',
  users: 'Аккаунты',
  organizationsSearchPlaceholder: 'Поиск',
  organizationStatus: {
    ALL: "Все",
    ACTIVE: 'Активный',
    INACTIVE: 'Неактивный',
  },
  noOrganizations: 'Здесь будет отображен перечень организаций',


  userStatus: {
    ALL: "Все",
    CREATED: 'Создан',
    ACTIVE: 'Активный',
    INACTIVE: 'Неактивный',
    LOCKED: 'Заблокированный'
  },
  role: {
    ADMIN: 'Администратор',
    LAWYER: 'Юрист',
    ACCOUNTANT: 'Бухгалтер',
    MANAGER: 'Менеджер',
    CHIEF_ACCOUNTANT: 'Главный бухгалтер',
    CHIEF_LAWYER: 'Главный юрист'
  },
  roleLabel: 'Роль',

  usersSearchPlaceholder: 'Поиск',
  noUsers: 'Здесь будет отображен перечень пользователей',
  newUser: 'Новый пользователь',
  email: 'E-mail',
  phone: 'Телефон',
  userName: 'Имя пользователя',
  addOrganizationsToUserModal: 'Добавить организации к пользователю',
  editUserRoleModalTitle: 'Изменить роль пользователя',
  selectOrganization: 'Выберите организацию',
  selectRole: 'Выберите роль',
  noOrganizationsAvailable: 'Нет доступных организаций',
  userOrganizationsLabel: 'Всего организаций',
  lastSeen: 'Последняя активность',
  sendLinkToActivate: 'Отправить ссылку для активации',
  sendActivationLinkModalTitle: 'Отправить ссылку для активации аккаунта',
  btnSend: 'Отправить',

  modalSubTitle: {
    setPass: 'Введите пароль',
    resetPass: 'Введите новый пароль'
  },
  passLabel: 'Введите пароль',
  repeatPassLabel: 'Повторите пароль',
  globalPermissionsLabel: 'Полномочия администратора',
  globalPermissionsPlaceholder: 'Выберите полномочия администратора',
  linkIsSent: 'Ссылка отправлена',
  myOrganizations: 'Мои организации',

  helloUser: (userName) => `Добро пожаловать, ${userName}!`,
  needToSetPass: 'Для работы в системе вам необходимо задать пароль',
  subTitleGoogleAuth: 'Настройка двухэтапной аутентификации',
  requiredSteps: 'Необходимые действия',
  googleAuthStep1: '1. Установите приложение Google Authenticator',
  googleAuthStep2: '2. Просканируйте в приложении QR-код',
  googleAuthStep3: '3. Введите код подтверждения из приложения',
  authCodeLabel: 'Код подтверждения',
  btnProve: 'Подтвердить',
  wrongAuthCode: 'Неправильный код подтверждения',
  passwordParams: 'Параметры пароля',
  passwordRules: {
    passMinLength: 'Минимум 8 символов',
    containsLatinUppercase: 'Латинские заглавные буквы (от A до Z)',
    containsLatinLowercase: 'Латинские строчные буквы (от a до z)',
    containsNumber: 'Цифры (от 0 до 9)',
    freeOfSpaces: 'Не содержит пробелы'
  },
  passwordsAreIdentical: 'Пароли совпадают',
  loginLabel: 'Логин',
  passwordLabel: 'Пароль',
  loginPassError: 'Неверный логин или пароль',

  organizationCode: 'Код организации',
  locale: 'Язык',
  organizationName: 'Название организации',
  newOrganization: 'Новая организация',
  deleteUserOrganizationModalTitle: 'Удаление',
  deleteUserOrganization1: `Удалить `,
  deleteUserOrganization2: ` из списка организаций пользователя?`,
  addParams: 'Дополнительные параметры',
  contractAddParams: 'Дополнительные параметры контракта',
  agentAddParams: 'Дополнительные параметры контрагента',
  paramType: {
    TEXT: 'Текстовое поле',
    TEXT_AREA: 'Большое текстовое поле',
    DATE: 'Дата'
  },
  deleteItemParamModalTitle: 'Удаление параметра из списка',
  deleteItemParam1: `Удалить `,
  deleteItemParam2: ` из списка дополнительных параметров для данной организации?`,
  editItemParamModalTitle: 'Редактирование параметра',
  addItemParamModalTitle: 'Добавление параметра',
  itemParamName: 'Название параметра',
  itemParamType: 'Тип ввода значения параметра',
  selectObjectType: 'Выберите тип',
  inputParamName: 'Введите имя параметра',
  itemParamLabel: 'Введите отображаемое имя параметра',
  contractTypes: 'Типы контрактов',
  sourceNamesPlaceholder: 'Выберите источники данных',
  currenciesPlaceholder: 'Выберите валюты',
  contractTypesPlaceholder: 'Введите тип контракта или выберите из списка',
  localePlaceholder: 'Выберите язык',
  noOptionsMessageForGroupFinTermType: () => 'Список типов условий будет доступен после выбора источника данных',

  adjustmentCaution: 'При проведении корректировки текущие данные будут утеряны без возможности восстановления. Вы уверены, что хотите продолжить?',
  author: 'Автор',
  reportNum1C: 'Номер акта в 1С',
  idReport: 'Id акта',

  reportTotalHint1: 'Задайте расчет для выбранных Финансовых условий с использованием формул, например:',
  reportTotalHintAdd: 'сложения',
  reportTotalHintSubtract: 'вычитания',
  reportTotalHintMultiply: 'умножение',
  reportTotalHintDivide: 'деления',
  reportTotalHint2: 'и также можно указывать просто числа (например, ',
  reportTotalHint3: 'Допускается одновременное использование нескольких условий',
  paramsHint1: 'При вводе в поле',
  paramsHint2: 'разрешается использовать только буквы! Для словосочетаний используйте CamelCase (например, НазваниеОрганизации, СуммаДокументаБезНДС).',
  paramNameError: 'Имя параметра не соответствует требованиям, указанным ниже',
  btnView: 'Посмотреть',
  btnViewTags: 'Посмотреть теги',
  viewTemplateParamsModalTitle: 'Теги для шаблона',
  searchPropName: {
    name: 'Название',
    tags: 'Теги',
    contractNo: 'Номер контракта',
    agentName: 'Связанный контрагент',
    contractName: 'Связанный контракт'
  },

  agentLinkedTypes: 'Типы связей контрагентов',
  contractLinkedTypes: 'Типы связей контрактов',
  agentLinkedTypesPlaceholder: 'Введите тип связи контрагента или выберите из списка',
  contractLinkedTypesPlaceholder: 'Введите тип связи контракта или выберите из списка',
  linkTypePlaceholder: 'Выберите тип связи',
  addServiceTypeList: 'Типы доп.услуг',
  addServiceTypeListPlaceholder: 'Введите тип доп.услуги',
  notedTerminalTypeList: 'Типы терминалов',
  notedTerminalTypeListPlaceholder: 'Введите тип терминала',
  folderSelectInModalLabel: "Каталог",
  folder:{
    name: 'Название',
    folderName:'Имя каталога',
    folder: 'Каталог',
    add: 'Добавить каталог',
    delete: 'Удаление',
    deleteText1: 'Вы уверены, что хотите удалить каталог',
    deleteText2: 'и',
    deleteText3: 'документов?',
    rename: 'Переименовать каталог',
    folders: 'Папки'
  },
  document:{
    name: 'Название',
    edit: 'Редактирование',
  },
  files:'Файлы',
  filesOutOfFolders:'Файлы вне папок',
  deleteContractAgentModal: 'Удаление',
  deleteLinkedContractModal: 'Удаление',
  deleteContractAgentModalText1: `Удалить `,
  deleteContractAgentModalText2: ` из списка связанных контрагентов?`,
  deleteLinkedContractModalText1: `Удалить `,
  deleteLinkedContractModalText2: ` из списка связанных контрактов?`,
  errorFileFormat: {
    oldDoc: 'Старый формат файла, используйте docx',
    oldXls: 'Старый формат файла, используйте xlsx',
    unknownFileFormat: 'Неизвестный формат файла'
  },
  allFiles: 'Все файлы списком',
  filesAndFolders: 'Папки и файлы',

  breadcrumbs: {
    supLevelCategory: {
      organizations: 'Организации',
      users: 'Аккаунты',
      settings: 'Настройки'
    },
    itemCategory: {
      contracts: 'Контракты',
      agents: 'Контрагенты',
      reports: 'Отчеты',
      documents: 'Документы',
      settings: 'Настройки',
      history: 'История'
    },
    newItem: {
      contract: 'Новый контракт',
      agent: 'Новый контрагент',
      user: 'Новый пользователь',
      organization: 'Новая организация'
    },
    newSubItem: {
      groupFinTerm: 'Новые условия',
      actTemplate: 'Новый шаблон акта',
      addService: 'Новая доп.услуга'
    },
    supLevelSubpage: {
      userOrganizations: 'Организации',
      history: 'История действий',
      notifications: 'Уведомления',
      params: 'Дополнительные параметры'
    },
    itemSubpage: {
      contracts: 'Связанные контракты',
      agents: 'Связанные контрагенты',
      history: 'История изменений',
      adjustmentHistory: 'История корректировок',
      groupFinTerms: 'Финансовые условия',
      documents: 'Документы',
      actTemplates: 'Шаблоны актов',
      notifications: 'Уведомления',
      addAgreements: 'Доп.соглашения',
      organization: 'Организация',
      'add-params': 'Дополнительные параметры',
      addServices: 'Доп.услуги',
      gatesParams: 'Параметры шлюзов'
    },
  },
  templateParamsHeader: {
    paramName: "Наименование поля",
    paramExample: "Пример значения",
    paramTag: "Имя переменной для использования в шаблонах отчётов",
    paramAliases: "Варианты имени переменнной для использования в шаблонах отчётов"
  },
  relationType: 'Тип связи',
  convertedTagModalTitle: `Добавление валюты`,
  convertedTagEditModeModalTitle: `Редактирование валюты`,
  convertedTagModalSubTitle: (paramName) => `для ${paramName}`,
  currencyConverterSource: {
    ECB: 'European Central Bank',
    CBR: 'Центральный банк РФ',
    REUTERS: 'Reuters',
    CURRENCY_LAYER: 'Currencylayer',
    OPEN_EXCHANGE: 'Open Exchange'
  },
  convertationDate: {
    DOCUMENT_DATE: 'Дата акта',
    TX_DATE: 'Дата транзакции'
  },
  currencyConverterSourceLabel: 'Источник конвертации',
  selectCurrencyPlaceholder: 'Выберите валюту',
  selectConvertorSourceBefore: 'Выберите источник конвертации перед выбором валюты',
  selectCurrencyConverterSourcePlaceholder: 'Выберите источник конвертации',
  selectConvertationDatePlaceholder: 'Выберите дату конвертации',
  convertationDateLabel: 'Дата конвертации',
  convertationDateError: 'Выберите дату конвертации',
  convertationSourceError: 'Выберите источник конвертации',
  convertationCurrencyError: 'Выберите валюту',
  clickToCopy: 'Кликните, чтобы скопировать',
  contentIsCopied: 'Скопировано в буфер',

  alertHeader: {
    ERROR: 'Ошибка!',
    WARNING: 'Внимание!',
    SUCCESS: 'Готово!',
    NOTIFICATION: 'Уведомление'
  },
  groupFinTermTemplateParams: {
    rateAbsAmount: 'Расчет по ABS',
    ratePercentDec: 'Расчет по % ставке',
    transactionType: 'Тип транзакции',
    rateDescription: 'Комиссия',
    totalAmount: 'Сумма операций',
    rateAmount: 'Сумма расчета по ставке',
    transactionsCount: 'Количество транзакций',
    hours: 'Количество часов',
    rate: 'Почасовая ставка',
    tracker: 'Трекер',
    manualInput: 'Ручной ввод'
  },
  reportSourceDoc: 'Исходные данные',
  reportAddendumDoc: 'Приложение',
  calculatedResultFile: 'Все приложения',
  calculatedResultAddendumFile: 'Приложение по шаблону',
  alertsList: {
    onSubmitEnabled: 'Вы внесли изменения в данные. Не забудьте сохранить их!',
    onActTemplateDocument: 'Файл выбран и будет сохранен при сохранении шаблона!',
    onAddendumTemplateFile: 'Файл выбран и будет сохранен при сохранении шаблона!',
    onEndDateIncompliance: 'Выбранная дата окончания прошла. После сохранения статус автоматически будет изменен на неактивный!',
    onFinTermDateIncompliance: 'Внимание! Ручная корректировка периода действия ФУ не соответствует периоду действия, указанному в Основном/дополнительном соглашение',
    onWrongFileName: `Название файла содержит запрещенный символ % и поэтому файл не может быть загружен. Переименуйте файл!`
  },
  reportTagExpressionPlaceholder: {
    reportTotalAmountExpression: 'Введите выражение для Суммы проведенных операций',
    reportTotalCountExpression: 'Введите выражение для Количества проведенных операций',
    reportTotalExpression: 'Введите выражение для Суммы итого',
  },
  filtersLabels: {
    userId: 'Инициатор',
    dateRange: 'Дата заключения',
    createDateRange: 'Дата создания',
    status: 'Статус',
    reportStatus: 'Статус выполнения',
    reportAccountStatus: 'Статус отчета',
    reportPeriodDateRange: 'Период отчета',
    contractId: 'Контракт',
    actTemplateId: 'Шаблон акта',
    payerId: 'Плательщик',
    payeeId: 'Получатель',
    organizationId: 'Организация',
    role: 'Роль',
    finTermGroupId: 'Финусловие',
    count: 'Показывать первых',
    text: 'Номер акта',
    groupFinTermName: "Название финусловия",
    payerMerchantId: "Торговец плательщика",
    payeeMerchantId: "Торговец получателя",
    currency: "Валюта финусловия",
    payoutPeriod: "Период выплат",
    cardType: "Тип карты",
    transactionStatus: "Статус транзакции",
    transactionType: "Тип транзакции",
    source: "Источник",
    gateShowMode: "Отбор шлюзов",
    gateSource: "Источник шлюза",
    gateCurrency: "Валюта шлюза",
    gateStatus: "Статус шлюза",
    terminalStatus: "Статус терминала",
    gateId: "Шлюз ID",
    gateName: "Название шлюза",
    terminalId: "Терминал ID",
    byAgentContracts: "По наличию контрактов",
    byAgentFinTerms: "По наличию ФУ",
    byComplianceWithPaynet: "По наличию ошибок",
    byUnverifiedAddAgreements: "По наличию непроверенных ДС",
    contractTypeId: "По типу контракта"
  },
  filterFlagsLabels: {
    ALL: 'Все ',
    onlyFinTerms: 'Фин.условия',
    onlyActTemplates: 'Шаблоны',
    flag1: 'Flag 1',
    flag2: 'Flag 2',
    flag3: 'Flag 3'
  },
  historyLogSectionTitle: {
    updated: 'Изменено',
    created: 'Добавлено',
    deleted: 'Удалено'
  },
  selectFilterPlaceholder: 'Фильтр',
  historyLog: {
    CONTRACT: {
      //contractMainData
      typeId: 'Тип',
      contractNo: 'Номер',
      name: 'Наименование',
      comment: 'Комментарий',
      startDate: 'Начало',
      endDate: 'Конец',
      contractCode1c: 'Код договора',
      tags: 'Теги',
      status: 'Статус',
      //fin terms
      payerId: 'Плательщик',
      payeeId: 'Получатель',
      payerMercAgentId: 'Торговец плательщика',
      payeeMercAgentId: 'Торговец получателя',
      type: 'Условия',
      nomenNumber1c: 'Код номенклатуры акта',
      projectName: 'Код номенклатурной группы (Проект/Направление)',
      ignoreInExport: 'Не учитывать при формировании файла экспорта данных',
      currency: 'Валюта',
      payoutPeriod: 'Период выплат',
      source: 'Источник данных',
      'sourceParam/gatewayId/textValue': 'Шлюз IDs',
      'sourceParam/merchantId/textValue': 'Мерчант IDs',
      'sourceParam/processorId/textValue': 'Процессор IDs',
      'sourceParam/terminalId/textValue': 'Терминал IDs',
      'sourceParam/transactionStatus/textValue': 'Статус транзакции',
      'sourceParam/transactionType/textValue': 'Тип транзакции',
      'sourceParam/projectId/textValue': 'Проект ID',
      'sourceParam/trackers/textValue': 'Имя трекеров',
      'sourceParam/userIds/textValue': 'Пользователь IDs',
      'sourceParam/usersExcluded/textValue': 'Исключить указанные IDs пользователя',
      'sourceParam/cardType/textValue': 'Тип карты',
      'sourceParam/managerId/textValue': 'Менеджер IDs',
      'sourceParam/direction/textValue': 'Тип направления',
      gateId: 'Шлюз ID',
      terminalIds: 'Терминал IDs',
      finTerm: 'Условие по дате',
      row: 'Диапазон',
      ratePercent: 'Процент',
      rateMin: 'Минимум',
      rateAbs: 'Абсолют',
      rateAbsForTxTypes: 'Стоимость',
      rateAbsForMinType: 'Минимум',
      minAmount: 'Сумма от',
      maxAmount: 'Сумма до',
      minCount: 'Количество от',
      maxCount: 'Количество до',
      rateHours: 'Почасовая ставка',
      documentName: 'Подтверждающий документ',
      groupId: 'ID Финусловия',
      id: 'ID',
      reportFileNamePattern: 'Название генерируемого документа',
      finTermGroupIds: 'Финусловия (IDs)',
      addAgreementId: 'Основное/дополнительное соглашение (ID)',
      reportTotalExpression: 'Сумма итого',
      manualInput: 'Ручной ввод',
      groupFinTerm: 'Финусловие',
      customFunction: 'Кастомная функция',
      //addAgreement
      fileId: 'Файл ID',
      number: 'Номер',
      description: 'Описание',
      contractId: 'Контракт ID',
      isNotVerifiedWithFinTerms: 'Не проверен. Есть изменение финансовых условий',
      isNotVerifiedWithFinTermsComment: 'Комментарий по изменениям финансовых условий',
      isCheckedAndVerified: "Доп.соглашение проверено",
      //addService
      userId: "Ответственное лицо",
      date: "Дата оказания",
      isDetachedInvoice: "Не учитывать при формировании файла экспорта данных",
      actTemplateId: "Шаблон акта",
      reportTemplateId: "Шаблон акта",
      addServiceSheetLabel: "Список услуг",
      addServiceCalculatedItemLabel: "Услуга",
      addServiceSheet: {
        addServiceTypeId: 'Название',
        price: 'Стоимость',
        count: 'Кол-во',
        amount: 'Сумма'
      },
      fileName: 'Название файла'


    },
    AGENT: {
      name: 'Наименование',
      tags: 'Теги',
      status: 'Статус',
      statusReason: 'Причина расторжения',
      agentCode: 'Код контрагента',
      inn1c: 'ИНН контрагента',
      name1c: 'Наименование контрагента',
      merchantId: 'Мерчант IDs',
      managerId: 'Менеджер IDs',
      processorId: 'Процессор IDs',
      fileName: 'Название файла',

      gateId: 'Шлюз ID',
      terminalId: 'Терминал ID',
      gateTerminalProps: {
        groupFinTerms: "Финусловия IDs",
        isIgnored: "Игнорируется",
        notedTerminalTypeId: "Тип терминала",
        comment: "Комментарий",
        commentUserId: "Автор комментария",
        commentDate: "Дата комментария"
      }
    }
  },
  reset: 'Сбросить',
  allRead: 'Отметить все как прочитанное',
  viewed: 'Просмотрено',
  unviewed: 'Непросмотрено',
  generatedDocName: 'Название генерируемого документа',
  generatedDocNameHintText1: 'Задайте название генерируемого файла используя теги в поддерживаемых функциях. ',
  generatedDocNameHintExample: 'Пример: Акт_${docNum}_от_${document_date}_${P_name}. ',
  generatedDocNameHintText2: 'Допускается создание названия документа без использования тегов',
  reportFileNamePatternPlaceholder: 'Введите выражение для названия генерируемого документа',
  pasteExampleFromHint: 'Вставить стандартное название',
  pasteAllTagsBtn: {
    reportTotalAmountExpression: 'Вставить Total Amount всех финусловий',
    reportTotalCountExpression: 'Вставить Total Count всех финусловий',
    reportTotalExpression: 'Вставить Rate Amount всех финусловий',
  },
  pasteAllTagsHint: 'При нажатии на кнопку в случае наличия тегов с конвертированной валютой, эти теги подставляются в выражение вместо тегов с исходной валютой',
  // reportTotalExpressionWarning: (rateAbs, currency) => `Внимание! Если сумма не превысит значение из финусловия ${rateAbs} ${currency}, то общая сумма будет равна размеру минимального значения ${rateAbs} ${currency}!`
  reportTotalExpressionWarning1: `Внимание! Если сумма не превысит минимальное значение из финусловий (`,
  reportTotalExpressionWarning2: `), то общая сумма будет равна размеру минимального значения!`,
  reportTagExpressionWarning: {
    reportTotalCountExpression: 'Внимание! Запрещено использование тегов в полях "Количество проведенных операций | ${report_total_count}" в выбранном Фин.Условии и в загружаемом Шаблоне Акта.',
    reportTotalAmountExpression: 'Внимание! Запрещено использование тегов в полях "Сумма проведенных операций | ${report_total_amount}" в выбранном Фин.Условии и в загружаемом Шаблоне Акта.'
  },
  rateAmountTagInput: 'Введите значение',
  fillManualInputsTitle: 'Заполните поля ручного ввода',
  excludeManualInputLabel: 'Исключить ручной ввод',
  rateAmountTagInputError: 'Введите значение или исключите ручной ввод!',
  generateAttachment: 'Сгенерировать приложение',
  showOther: 'еще',
  reportPeriodIncompliance: 'Внимание! Период отчета не соответствует датам финусловий!',
  reportPeriodIsNotCorrespondToLastValidReportDate: (date) => `Генерация отчета разрешена до ${date}`,
  wrongDateRange: (date) => `Дата начала должна быть раньше даты окончания.`,
  linksToGroupFinTermsLabel: "Ссылки на финусловия: ",
  addFinTermTooltip: 'Для добавления нового ФУ нажмите на выделенную область. После добавления проверьте период дат в текущем ФУ, даты не должны пересекаться.',
  finTermParamTooltip: {
    BY_VOLUME_SIMPLE: byVolumeParamTooltips,
    BY_VOLUME_COMPLEX: byVolumeParamTooltips,
    BYRATE: byVolumeParamTooltips,
    BY_HOURS: {
      rateHours: 'Укажите ставку согласно выбранной валюте в блоке «Валюта» в финансовом условии',
    },
    BY_TX_COUNT: byTxFinTermParamTooltips,
    BY_TX_COUNT_SIMPLE: byTxFinTermParamTooltips,
    BY_TX_COUNT_COMPLEX: byTxFinTermParamTooltips,
    FIXED: {
      rateAbs: 'Фиксированный платеж, исходя из расчета выбранного периода выплат (месяц/квартал/год), применяется на срок действия даты или периода дат. Указанная сумма будет рассчитана исходя из выбранного периода выплат, с учетом выбора дат при формировании акта.'
    },
    MANUAL: {},
    MIN: {
      rateAbs: 'Минимальный платеж применяемый после расчета всех добавленных фин.условий, применяется на срок действия даты или периода дат. Если указанная сумма не превысит минимальное значение после расчета всех добавленных фин.условий, то общая сумма будет равна размеру всех добавленных фин.условий.'
    }
  },
  sourceParamTooltip: {
    trackers: 'Укажите "Task, Bug, User story, Bug story", через запятую',
    userIds: 'Укажите ID пользователя или нескольких пользователей, через запятую с пробелом'
  },
  groupFinTermTypeTooltip: {
    BY_VOLUME_SIMPLE: 'Расчет по введенным параметрам с указанным диапазоном сумм в полях "Сумма от" и "Сумма до". Допускается указание неограниченного числа диапазона сумм с соблюдением логической последовательности',
    BY_VOLUME_COMPLEX: 'Расчет по введенным параметрам с указанным диапазоном сумм в полях "Сумма от" и "Сумма до" нарастающим итогом. Нарастающий итог подразумевает расчет по промежуточным значениям, указанным в полях "Сумма от" и "Сумма до" по истечению каждого промежуточного периода',
    BYRATE: 'Расчет по фиксированной ставке с параметрами, указанными в условии. Применяется на срок действия даты или периода дат',
    BY_HOURS: '',
    BY_TX_COUNT: 'Расчет по фиксированной сумме, указанной в поле "Стоимость" и умноженной на количество транзакций (потранзакционный расчет) за указанный период дат',
    BY_TX_COUNT_SIMPLE: 'Расчет по указанному диапазону транзакций в полях "Количество от" и "Количество до" за указанный период дат. Допускается указание неограниченного числа диапазона транзакций с соблюдением логической последовательности',
    BY_TX_COUNT_COMPLEX: 'Расчет по указанному диапазону транзакций в полях "Количество от" и "Количество до" за указанный период дат нарастающим итогом. Нарастающий итог подразумевает расчет по промежуточным значениям, указанным в полях "Количество от" и "Количество до" по истечению каждого промежуточного периода',
    FIXED: 'Расчет по фиксированной сумме с параметрами указанными в условии, применяется на срок действия даты или периода дат',
    FIXED_NO_PERIOD: 'Расчет по фиксированной сумме с параметрами указанными в условии, применяется без учета срока действия даты или периода дат',
    MANUAL: 'При выборе финансового условия, при генерации отчета будет доступна опция ввода значения вручную',
    MIN: 'ТОЛЬКО совместное использование с другими фин.условиями. Минимальный платеж применяемый после расчета всех добавленных фин.условий, применяется на срок действия даты или периода дат',
  },
  groupFinTermSourceTooltip: {
    PNE: 'Расчет и формирование данных по заданным параметрам при обращении к внешнему сервису PayNetEasy Gateway RU',
    PNE_EU: 'Расчет и формирование данных по заданным параметрам при обращении к внешнему сервису PayNetEasy Gateway EU',
    REDMINE: 'Расчет и формирование данных по заданным параметрам при обращении к внешнему сервису Redmine',
    EMPTY: 'Расчет и формирование данных по заданным параметрам в Системе FlowDoc, без обращения к сторонним сервисам',
  },
  payerTooltip: "Физическое или Юридическое лицо, которое платит за товар или услугу указанному Получателю",
  payeeTooltip: "Физическое или Юридическое лицо, в пользу которого производится платеж указанным Плательщиком за товар или услугу",
  currencyTooltip: "Исходный тип валюты расчета транзакций по указанным параметрам в Источнике данных",
  payoutPeriodTooltip: 'Период выставления счета за оказанные услуги. Расчет по заданным параметрам (сумма, период) в Финансовом условии, при выборе Источника данных: “Без источника”',
  param1CTooltip: 'Заполните параметры для формирования файла выгрузки в формате .csv. Файл доступен при переходе в статусы: "Подготовлен” или “Скорректирован”',
  ignoreInExportTooltip: "При выборе чек-бокса, данное Финансовое условие не будет учитываться при формировании выгрузки в формате .csv",
  moreLinkTo: 'Подробнее',
  addItemRowHeading: {
    name: 'Наименование',
    createDate: 'Дата создания',
    status: 'Статус',
    statusDate: 'Дата расторжения',
    contractNo: 'Номер',
    validityTerm: 'Период действия',
    contractType: 'Тип'
  },
  settingsTitle: 'Настройки',
  orgSettingsTitle: 'Настройки',
  notificationMenuTitle: 'Уведомления',
  organizationSettingsMenuTitle: 'Организация',
  backToUp: 'Назад',
  notificationSettingsTitle: 'Настройки нотификаций',
  notificationSettingsFormHeader: {
    organizationHeader: 'Организация',
    AGENT_NOTFICATION: 'Контрагенты',
    CONTRACT_NOTIFICATION: 'Контракты',
    LINKED_AGENT_NOTIFICATION: 'Связанные контрагенты',
    LINKED_CONTRACT_NOTIFICATION: 'Связанные контракты',
    FINTERM_NOTIFICATION: 'Финансовые условия',
    CONTRACT_END_DATE_NOTIFICATION: 'Срок действия контрактов',
    ADD_AGREEMENT_END_DATE_NOTIFICATION: 'Срок действия допсоглашений',
    FIN_TERM_END_DATE_NOTIFICATION: 'Срок действия финусловий',
    ALL: 'Все'
  },
  notificationSettingsInputValue: {
    yes: 'Да',
    no: 'Нет'
  },
  notificationForAllOrgsLabel: 'Все',
  organizationLinkedAgent: 'Связанный контрагент',
  linkedAgentIsNotSelected: 'Связанный контрагент не выбран',

  addAgreementsDates: 'Дата подписания',
  createAddAgreementModalTitle: 'Добавление доп.соглашения',
  editAddAgreementModalTitle: 'Редактирование доп.соглашения',
  addAgreementName: 'Название',
  addAgreementFileLabel: 'Файл основание',
  newAddAgreementFile: 'Новый',
  fromDocumentsList: 'Из списка',
  fileFromDocumentsListLabel: 'Список загруженных документов',
  selectStatusPlaceholder: 'Выбрать статус',
  fileIsNotChosen: 'Файл не выбран',
  deleteAddAgreementModalTitle: 'Удаление',
  deleteAddAgreementConfirmation: `Удалить `,
  deleteWithFileCheckboxLabel: (fileName) => `Удалить связанный документ ${fileName}`,
  fileWithIdNotFound: (fileId) => `Файл с ID=${fileId} не найден в списке документов`,
  isNotVerifiedWithFinTerms: 'Наличие изменения финансовых условий или расчетов',
  isNotVerifiedWithFinTermsComment: 'Комментарий по изменениям финансовых условий или расчетов',
  isNotVerifiedWithFinTermsOptions: {
    yes: "Да",
    no: "Нет"
  },
  isNotVerifiedWithFinTermsPlaceholder: 'Укажите наличие изменений',
  isCheckedAndVerified: "Доп.соглашение проверено",

  fileLocation: 'Расположение',
  withoutFolder: 'Без папки',
  contextSnippetLabel: 'Контекст совпадения',
  filterCheckboxLabel: {
    strictPeriod: 'Строгий период'
  },
  documentIndexStatusTooltip: {
    INDEXED: 'Документ успешно проиндексирован',
    NO_CONTENT: 'Документ без содержания и поэтому непроиндексирован',
    ERROR: 'Ошибка индексации',
    PENDING: 'Документ в процессе индексации',
    NOT_APPLICABLE: 'Документ непроиндексирован'
  },
  searchInOrgContextBtnLabel: 'Поиск',
  verifiedStatusText: {
    VERIFIED: 'Проверен',
    UNVERIFIED: 'Не проверен'
  },
  isVerifiedLabel: 'Проверен',
  updatesNotificationsTitle: 'Получать уведомления на создание/изменения/удаления основных сущностей',
  endDatesNotificationsTitle: 'Получать уведомления о сроке действия сущностей',
  endDatesNotificationsNote: '*Уведомления будут приходить за 30, 15, 7 и 0 (по факту изменения статуса) суток.',
  textReportFilterPlaceholder: 'Введите номер акта',
  fileWillBeDeleted: 'Файл будет удален после Сохранения изменений',

  selectAddAgreementOrMainContract: 'Выберите базовое соглашение',
  scrollToTop: '↑ Наверх ↑',
  editBtnLabel: 'Редактировать',
  deleteBtnLabel: 'Удалить',
  restoreBtnLabel: 'Восстановить',

  applyCustomFunction: 'Применить кастомную функцию',
  customFunction: 'Кастомная функция',

  dateRangePeriodTypeLabels: {
    day: 'По дням',
    month: 'По месяцам',
    quarter: 'По кварталам'
  },
  btnDenyDatesLabel: 'Отмена',
  btnApplyDatesLabel: 'Готово',
  monthOptionsLabels: {
    '01':'Январь',
    '02':'Февраль',
    '03':'Март',
    '04':'Апрель',
    '05':'Май',
    '06':'Июнь',
    '07':'Июль',
    '08':'Август',
    '09':'Сентябрь',
    '10':'Октябрь',
    '11':'Ноябрь',
    '12': 'Декабрь'
  },
  quarterOptionsLabels: {
    '1': 'I',
    '2': 'II',
    '3': 'III',
    '4': 'IV'
  },
  selectMonthPlaceholder: 'Месяц',
  selectQuarterPlaceholder: 'Квартал',
  selectYearPlaceholder: 'Год',
  selectedQuarterLabel: 'Выбранный квартал:',

  contractAgentsDisabledDeleteTooltip: 'К контрагенту привязаны фин.условия. Удаление невозможно!',
  addAgreementsDisabledDeleteTooltip: (finTermGroupIds) => `Доп.соглашение используется в фин.условиях: ${finTermGroupIds}`,

  addServices: 'Доп.услуги',
  btnNewAddService: 'Добавить услугу',
  newAddServiceHeading: 'Новая дополнительная услуга',
  editModeStr: 'Редактирование',
  addServiceEditSteps: {
    type: 'Тип услуги',
    docs: 'Документы',
    sheets: 'Список услуг',
    confirmation: 'Подтверждение'
  },
  addServiceFormLabels: {
    status: 'Статус',
    nomenNumber1c: 'Код номенклатуры акта',
    projectName: 'Код номенклатурной группы (Проект/Направление)',
    name: 'Название услуги',
    userId: 'Ответственное лицо',
    date: 'Дата оказания',
    payoutPeriod: 'Период выставления счета',
    ignoreInExport: 'Не учитывать при формировании файла экспорта данных',
    isDetachedInvoice: 'Выставить отдельным счетом',
    actTemplateId: 'Шаблон акта'
  },
  addServiceFormPlaceholders: {
    name: 'Введите название услуги',
    userId: 'Выберите ответственное лицо',
    payoutPeriod: 'Выберите период',
    nomenNumber1c: 'Введите код номенклатуры акта',
    projectName: 'Введите код номенклатурной группы',
    actTemplateId: 'Выберите шаблон акта, в который будет внесен счет на услугу'
  },
  addServiceFormNoOptionsMessage: {
    userId: () => 'Список пользователей пуст. Обратитесь к администратору',
    payoutPeriod: () => 'Список периодов пуст. Обратитесь к администратору',
    actTemplateId: () => 'Список шаблонов пуст'
  },
  addServiceFormLabelTooltips: {
    actTemplateId: 'Шаблон акта, в который будет внесен счет на услугу'
  },
  addServiceInvoiceSubheader: 'Выставление счета',
  addServiceDocsSubheader: 'Подтверждающий документ',
  stepsNavigationButtons: {
    cancel: 'Закрыть',
    back: 'Назад',
    forward: 'Далее'
  },
  addServiceFinishButtonLabel: {
    update: 'Сохранить',
    create: 'Добавить'
  },
  sheetLabels: {
    baseCurrency: 'Основная валюта',
    secCurrency: 'Дополнительная валюта',
    totalAmount: 'Итоговая стоимость'
  },
  sheetPlaceholders: {
    currency: 'Выберите валюту'
  },
  sheetNoOptionsMessage: {
    currency: 'Список валют пуст. Обратитесь к администратору'
  },
  sheetCalculatedItemsHeader: {
    addServiceTypeId: 'Название',
    price: 'Стоимость',
    number: 'Кол-во',
    amount: 'Сумма'
  },
  sheetCalculatedItemPlaceholders: {
    addServiceTypeId: 'Выберите услугу',
  },
  sheetCalculatedItemNoOptionsMessage: {
    addServiceTypeId: 'Список услуг пуст. Обратитесь к администратору'
  },
  addSheetButtonLabel: '+ Дополнительная валюта',
  addServiceDescSubheader: 'Описание услуги',
  addServiceSheetsSubheader: 'Список услуг',
  addServiceViewSubheader: 'Дополнительная услуга',
  deleteAddServiceModal: {
    title: 'Удаление',
    confirmation: `Удалить `,
    preventDeleteTitle: 'Удаление и редактирование дополнительной услуги невозможно',
    preventDeleteText: (addServiceName) => `На основе дополнительной услуги ${addServiceName} подготовлен отчет`,
    withFiles: (fileNames) => `Удалить связанные документы: ${fileNames}`
  },
  showAllBtnLabel: 'Показать все',
  hideAllBtnLabel: 'Скрыть все',

  payoutPeriodIsNotSet: 'Период выплат не указан',
  payoutPeriodIsNotCompliedWithAddService: '- не совпадает с периодом выплат в данной доп.услуге',

  actTemplateCardHeadings: {
    finTerms: 'Фин. условия',
    addServices: 'Доп. услуги'
  },
  noAddServicesForGivenPeriod: 'За выбранный период доп. услуги не найдены',
  addServiceWithGivenIdIsNotFound: (id) => `Доп. услуга с id=${id} не найдена`,
  uploadedAddServicesAmountWarning: (amount) => `Количество выставленных счетов по доп. услугам - ${amount}`,
  addServiceHasReportsWarning: 'Данная услуга уже используется в отчетах: ',

  finTermGatesSectionTitle: "Параметры для получения транзакций",
  gateIdLabel: "Шлюз ID",
  terminalsLabel: "Терминал IDs",
  terminalsPlaceholder: "Выберите терминалы",
  terminalsNoOptionsMessage: "Все терминалы выбраны",
  terminalsOptionsMenuTerminalCaption: "Терминал",
  terminalsOptionsMenuGroupFinTermCaption: "Фин.условия",

  gatesParams: "Параметры шлюзов",
  gatesParamsCardHeader: {
    gateId: "Шлюз ID",
    source: "Источник",
    currency: "Валюта",
    gateName: "Название",
    gateStatus: "Текущий статус",
    gateCreationDate: "Создан:",
    gateChangeDate: "Изменен:",
    gateIgnored: "Игнорируется"
  },
  terminalLabelPrefix: "Терминал ",
  isIgnoredTrueValue: "Игнорируется",
  isIgnoredFalseValue: "Не игнорируется",
  ignoreBtnLabel: "Игнорировать",
  clickToIgnore: "Нажмите, чтобы игнорировать",
  clickToResetIgnore: "Нажмите, чтобы отменить игнорирование",
  ignoreGateBtnLabel: "Игнорировать все",
  selectFinTermBtnLabel: "Выбрать ФУ",
  editFinTermBtnLabel: "Изменить",
  selectFinTermModalTitle: (terminalId) => `Финансовое условие для терминала ${terminalId}`,
  selectFinTermsForGateModalTitle: (gateId) => `Финансовые условия для терминалов шлюза ${gateId}`,
  selectFinTermsForChosenGatesModalTitle: (gateIdArr) => `Финансовые условия для всех терминалов шлюзов ${gateIdArr}`,
  onlyChosenBtnLabel: "Только выбранные",
  allBtnLabel: "Все",
  applyBtnLabel: "Применить",
  gatesParamsCardAlert: "Не участвует в ФУ",
  requestPaynetGatesParamsButtonLabel: 'Обновить базу',
  alertPaynetSyncLabel: "Ошибка синхронизации. Отличие данных с PPG PNE.",
  alertPaynetSyncReportGenerationIsProhibited: "Генерация отчета запрещена.",
  alertPaynetSyncReportGenerationIsAllowed: (date) => `Генерация отчета разрешена до ${date}.`,
  linkToNotCompliedAgent: "Перейти к контрагенту",
  linkToContract: "Перейти к контракту",
  alertUnverifiedContractFinTerm: "Фин. расчет не проверен",
  alertUnverifiedAddAgreementsLabel: "Есть непроверенные доп.соглашения.",
  alertUnverifiedAddAgreementsGenerationIsProhibited: "Генерация отчета запрещена.",
  linkToUnverifiedAddAgreements: "Перейти к доп.соглашениям",
  addSourceAndMerchantId: "+ Добавить источник",
  addSourceAndManagerId: "+ Добавить источник",
  addSourceAndProcessorId: "+ Добавить источник",
  requestPaynetDateEmpty: "Дата обновления",
  terminalIsNotLinkedToFinTerm: "Не привязан",
  finTermGatesArrayIsEmpty: "Список шлюзов и терминалов пуст. Проверьте указан ли Мерчант IDs в  ",
  finTermGatesArrayIsEmptyLink: "Основных данных Плательщика",
  newSourceParamsSectionTitle: "Параметры для расчета",
  gateStatusLabel: {
    E: "Включен",
    D: "Выключен"
  },
  terminalStatusLabel: {
    E: "Включен",
    D: "Выключен"
  },

  btnAddReportTagExpressionLabel: "Добавить расчет",
  reportTagExpressionName: `report_tag_`,
  operationLabel: {
    "FT": "FT",
    "ΣFT": "ΣFT",
    "TAG": "ТЕГ",
    "VAT": "НДС",
    "currency": "CUR",
  },
  selectTagPlaceholder: "Выберите тег",
  selectReportTagExpressionFinTermPlaceholder: "Выберите ФУ",
  reportTagExpressionsError: {
    unequalBracketsNumber: "Количества открывающих и закрывающих скобок не равны",
    moreThanOneParenthesis: "Внутри скобок может быть только одно вложенное выражение в скобках",
    parenthesisIsNotAfterOpenBracket: "Выражение в скобках должно идти сразу после открывающей скобки",
    firstOrderComputationIsNotAfterOpenBracket: "Первочередное арифметическое действие должно идти сразу после открывающей скобки",
    numberIsInUnsupportedOperation: "Числа не поддерживаются в данном арифметическом действии",
    tagIsMultiplier: "Тег не может быть множителем",
    wrongNumberMultiplier: "Неправильная форма числа в множителе"
  },

  sortAndFilterBar: {
    inputPlaceholder: "Поиск",
    inputPropsLabels: {
      gatesParams: {
        ALL: "По всем параметрам",
        gateId: "По шлюз ID",
        source: "По источнику",
        gateStatus: "По статусу шлюза",
        terminalIds: "По терминал ID"
      }
    },
    sortOptionsLabels: {
      gateId_sort_top: "Шлюз ID",
      gateId_sort_down: "Шлюз ID",
      gateCreationDate_sort_top: "Создан",
      gateCreationDate_sort_down: "Создан",
      gateChangeDate_sort_top: "Изменен",
      gateChangeDate_sort_down: "Изменен",
    },
    filterMenuLabel: {
      source: "Источник",
      gateStatus: "Текущий статус",
      gateCreationDate: "Создан",
      gateChangeDate: "Изменен"
    },
    clearFilterBtnLabel: "Очистить фильтр - "

  },
  infoPanelLabel: {
    total: "Всего найдено:",
    found: "Найдено",
    chosen: "Выбрано",
    choose: "Выбрать:",
    actions: "Действие с выбранным",
    of: "из"
  },
  sortAndFilterInfoPanel: {
    ignoreBtnLabel: "Игнорировать",
    selectFinTermBtnLabel: "Выбрать ФУ",
    cleanFinTermsAndIgnores: "Обнулить"
  },
  gateShowModeOptions: {
    withErrors: "С ошибками",
    withoutErrors: "Без ошибок",
    withIgnores: "Игнорируемые",
    all: "Все",
  },
  yesLabel: "Да",
  noLabel: "Нет",

  confirmActionModal: {
    modalTitle: "Подтверждение действия",
    modalActionBtnText: "Подтвердить",
    modalCancelBtnText: "Отменить"
  },
  confirmIgnoreSelectedGates: "Игнорировать выбранные шлюзы и их терминалы?",
  confirmCleanFinTermsAndIgnoresForChosenGates: "Очистить списки финансовых условий и отменить игнорирование для выбранных шлюзов и связанных терминалов?",
  confirmDeleteCommentAndNotedType: "Удалить комментарий и тип терминала?",


  addMerchantBtnLabel: "+ Добавить торговца",
  removeMerchantBtnLabel: "Удалить торговца",
  sortSelectLabel: "Сортировка",
  sFFiltersListLabel: "Фильтр",

  groupFinTermsFilters: {
    groupFinTermNamePlaceholder: "Введите название финусловия"
  },
  gatesParamsFilters: {
    gateIdPlaceholder: "Введите ID шлюза",
    gateNamePlaceholder: "Введите название шлюза",
    terminalIdPlaceholder: "Введите ID терминала",
  },
  gatesParamsCardComment: {
    modalTitle: "Комментарий",
    modalCancelBtnText: "Отмена",
    modalActionBtnText: "Готово",
    notedTerminalTypeIdLabel: "Тип терминала",
    notedTerminalTypeIdLabelPlaceholder: "Выберите тип",
    notedTerminalTypeIdNoOptions: "Добавьте типы в ",
    notedTerminalTypeIdNoOptionsLinkLabel: "настройках",
    commentLabel: "Комментарий"
  },
  searchPageFiltersLabels: {
    WITH_CONTRACTS: "С контрактами",
    WITHOUT_CONTRACTS: "Без контрактов",
    WITH_FINTERMS: "С финусловиями",
    WITHOUT_FINTERMS: "Без финусловий",
    WITH_ERRORS: "С ошибками",
    WITHOUT_ERRORS: "Без ошибок",
    byAgentContractsPlaceholder: "По наличию контрактов",
    byAgentFinTermsPlaceholder: "По наличию ФУ",
    byComplianceWithPaynetPlaceholder: "По наличию ошибок",
    byUnverifiedAddAgreementsPlaceholder: "По наличию непроверенных допсоглашений",
    contractTypeId: "По типу контракта"
  },

  modalCloseBtnLabel: "Закрыть",

  currentDocNumLabel: "Номер акта",
  currentDocNumCheckboxLabel: "Присвоить номер",

  editTitleLabel: "Редактирование",
  editTitleSuffix: " | ред",
  subHeaderTitle: {
    newGroupFinTerm: 'Новое фин.условие',
    newActTemplate: "Новый шаблон акта",
    newAddService: "Новая доп.услуга"
  },
  allOrganizationsLabel: "Все организации",
  contractMenu: {
    mainData: "Основное",
    addAgreements: "Доп.соглашения",
    finTerms: "Фин.условия",
    relatedAgents: "Контрагенты",
    relatedContracts: "Контракты",
    documents: "Документы",
    actTemplates: "Шаблоны актов",
    addServices: "Доп.услуги",
    history: "История",
    sources: "Источники",
    gatesParams: 'Параметры шлюзов',
  },

  noOptionsToSelect: "Нет доступных опций для выбора",
  emptyListV2Label: "Тут ничего нет!",

  deleteAgentForbiddenReason: {
    CONNECTED_TO_CONTRACT: "Удаление невозможно. Привязан к контракту",
    HAS_MERCHANT_ID: "Удаление невозможно. Указан Мерчант ID",
    HAS_MANAGER_ID: "Удаление невозможно. Указан Менеджер ID",
    HAS_PROCESSOR_ID: "Удаление невозможно. Указан Процессор ID",
    HAS_FILE: "Удаление невозможно. Загружен файл"
  },

  countriesDirectionsLabelTitle: "Направления и страны",
  cardCountryCodesPlaceholder: "Выберите страны",
  addDirectionAndCountryCodes: "+ Добавить направление",
  directionLabel: "Направление",
  cardCountryCodes: "Страны",

  datepicker: {
    january: "Январь",
    february: "Февраль",
    march: "Март",
    april: "Апрель",
    may: "Май",
    june: "Июнь",
    july: "Июль",
    august: "Август",
    september: "Сентябрь",
    october: "Октябрь",
    november: "Ноябрь",
    december: "Декабрь",
    monday: "Пн",
    thursday: "Вт",
    wednesday: "Ср",
    tuesday: "Чт",
    friday: "Пт",
    saturday: "Сб",
    sunday: "Вс"
  }
};

export default textRu;
